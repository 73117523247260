import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row, Col} from 'react-bootstrap'
import {SectionWrap} from './index.style'
import SectionTitle from '../../../../shared-ui/components/ui/section-title'
import SwiperSlider from '../../../../shared-ui/components/ui/swiper'
import Testimonial from '../../../../shared-ui/components/testimonial/layout-one'

const Section = ({SectionTitleStyle, slider, testimonials, sliderStyle}) => {    
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col md={12}>
                        <SectionTitle
                            {...SectionTitleStyle}
                            subtitle="Testimonios Reales"
                            title="¿Porqué nuestros clientes <span>nos prefieren?</span>"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <SwiperSlider settings={slider} {...sliderStyle}>
                            {testimonials.map((testimonial, i) => {
                                return(
                                    <div key={i} className="item">
                                        <Testimonial
                                            //authorImg={testimonial.authorImg}
                                            authorName={testimonial.customerName}
                                            authroRole={testimonial.customerJobTitle}
                                            review={testimonial.customerReviewMessage.customerReviewMessage}
                                            rating={testimonial.ratingScore}
                                        />
                                    </div>
                                )
                            })}
                        </SwiperSlider>
                    </Col>
                </Row>
            </Container>
        </SectionWrap>
    )
}

Section.propTypes = {
    SectionTitleStyle: PropTypes.object   
} 

Section.defaultProps = {
    SectionTitleStyle: {
        mb: '40px'
    },
    slider: {
        slidesPerView: 2,
        loop: true,
        speed: 1000,
        spaceBetween: 30,
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            320: {
                slidesPerView : 1
            },
            991: {
                slidesPerView : 2
            }
        }
    },
    sliderStyle: {
        pagination: {
            mt: '30px'
        }
    }
}


export default Section;