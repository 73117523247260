import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../../../shared-ui/layouts/standard'
import LightLogo from '../assets/images/logo/light-logo.png'
import DarkLogo from '../assets/images/logo/logo-cuatrotorres-v2.png'

const CustomLayout = ({children, location}) => {  
  
  const { allHeadermenuJson, allFootermenuJson, allHeadlineJson, site } = useStaticQuery(graphql`
        query GET_LANDING_MENU {
            
            allHeadermenuJson {
                edges {
                    node {
                        text
                        link
                        id                        
                    }
                }
            }
            allFootermenuJson {
              edges {
                  node {
                      text
                      link
                      id
                      submenu {
                        link
                        text
                      }
                  }
              }
          }
          allHeadlineJson {
              edges {
                node {
                  isVisible
                  introText
                  text
                  link
              }
            }
          }
          site {
              siteMetadata {
                  title
                  description
                  author
                  siteUrl
                  canonical
                  siteLanguage
                  image
                  titleTemplate
                  twitterUsername

                  copyright
                    contact {
                        phone
                        email
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
              }
              buildTime
            }
        } 
    `);

  return (
    <Layout location={location} menuData={allHeadermenuJson} 
        headline={allHeadlineJson.edges[0].node} footerMenu={allFootermenuJson} site={site} lightLogo={LightLogo} darkLogo={DarkLogo} >           
      {children}
    </Layout>)
}
 
CustomLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
  
export default CustomLayout