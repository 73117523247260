import styled from "styled-components";
import {device} from '../../../../shared-ui/theme'

export const SectionWrap = styled.div `
    background: #f8f8f8;    
    padding-top: 120px;
    padding-bottom: 120px;
    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 80px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 60px;
    }
`;
